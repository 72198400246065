<template>
    <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
    width="35%" :title="`${isEdit ? '编辑' : '添加'}绑定`" :visible.sync="isShow">
    <!-- <select-map :localChange='onCallbackMarker' ref='map'></select-map> -->
    <div class="all-input">
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>对象类型：</div>
        <el-select
          @change="clearID"
          style="width: 50%;!important"
          v-model="formData.type"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in objType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div v-show="formData.type" style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择对象：</div>
        <el-select
          style="width: 50%;!important"
          v-model="id"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in formData.type === '1' ? pArr : dArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择设备：</div>
        <el-select
          style="width: 50%;!important"
          v-model="formData.u_id"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in uArr"
            :key="item.id"
            :label="item.unit_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>地理位置：</div>
        <div style="width: 50%;!important" @click="openMap" class="map">{{ formData.address || '选择地图' }}</div>
      </div>
    </div>
    <select-map :localChange='onCallbackMarker' ref='map'></select-map>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getVideoSelect, addVideo, updVideo } from '@/api/bind'
import selectMap from '@/components/selectMap'

export default {
  name: 'addInd',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    selectMap,
  },
  data () {
    return {
      isEdit: false,
      isShow: true,
      objType: [
        { id: '1', name: '工程' },
        { id: '2', name: '深基坑' },
      ],
      formData: {
        type: '',
        d_id: '',
        p_id: '',
        u_id: '',
        lng: '',
        lat: '',
        address: '',
      },
      id: '',
      uArr: [], // 设备下拉
      pArr: [], // 工程下拉
      dArr: [], // 坑下拉
      address: '',
    }
  },
  created() {
    this._loadSelect()
    if (this.data.id) {
      this.isEdit = true
      this.nickname = this.data.nickname
      const { type, d_id: did, p_id: pid, lng, lat, address, u_id: uid, id } = this.data
      this.formData = { type: String(type), d_id: did, p_id: pid, u_id: uid, id, lat, lng, address }
      if (String(type) === '1') this.id = pid
      else if (String(type) === '2') this.id = did
    }
  },
  methods: {
    _loadSelect: function() {
      getVideoSelect().then(res => {
        const { result } = res
        this.uArr = result.unit
        this.pArr = result.project
        this.dArr = result.deeppit
      })
    },
    clearID: function() {
      this.id = ''
    },
    submit: function() {
      // console.log(this.list)
      if (!this.id && !this.formData.bind_id) {
        this.$toast('请选择 选择对象')
        return false
      } else if (!this.formData.type) {
        this.$toast('请选择 对象类型')
        return false
      } else if (!this.formData.u_id) {
        this.$toast('请选择 选择设备')
        return false
      } else if (!this.formData.address) {
        this.$toast('请选择 地理位置')
        return false
      }
      if (this.formData.type === '1') {
        this.formData.p_id = this.id
      } else if (this.formData.type === '2') {
        this.formData.d_id = this.id
      }
      console.log(this.formData)
      // this.formData.type = this.formData.type

      this.isEdit ? this.edit() : this.save()
    },
    save: function() {
      addVideo(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    edit: function() {
      updVideo(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    close: function() {
      this.isShow = false
      this.$emit('close')
    },
    openMap: function() {
      this.$refs.map.open()
    },

    // 获取当前坐标点
    onCallbackMarker: function({ address, location }) {
      // console.log('地址：', address)
      // console.log('经纬度：', location)
      this.formData.address = address
      this.formData.lat = location.lat
      this.formData.lng = location.lng
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
